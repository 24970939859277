import axios from 'axios'
import JSONBig from 'json-bigint'
import encrypt from './utils/encryptDES'

const formRequest = axios.create({
    // 接口的基准路径
    baseURL: 'http://tssywx.tssrmyy.com/wxyb',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    transformResponse: [function (res) {
        try {
            let data = JSONBig.parse(res)
            if (data.data) {
                data.data = JSONBig.parse(encrypt.decryptByDES(data.data))
            }
            return data
        } catch (err) {
            return res
        }
    }]
})

export default formRequest