import axios from "axios";
import JSONBig from "json-bigint";
import encrypt from "./utils/encryptDES";

const jsonRequest = axios.create({
  // 接口的基准路径
  baseURL: "http://tssywx.tssrmyy.com/wxyb",
  transformResponse: [
    function(res) {
      try {
        let data = JSONBig.parse(res);
        if (data.data) {
          data.data = JSONBig.parse(encrypt.decryptByDES(data.data));
        }
        return data;
      } catch (res) {
        return res;
      }
    }
  ]
});

export default jsonRequest;
