import formRequest from '@/formRequest'
import jsonRequest from '@/jsonRequest'
import encrypt from './utils/encryptDES'
import qs from "qs";
export const jsonPost = function (url, data = {}) {
    return jsonRequest({
        url: url,
        method: 'POST',
        data,
        headers: {
            'openId': window.localStorage.getItem('openId'),
            'time': encrypt.encryptByDES(JSON.stringify(Date.now())),
            'Token': encrypt.encryptByDES(JSON.stringify(qs.parse(data)))
        }
    })

}

//表单 post请求
export const formPost = function (url, data = {}) {
    return formRequest({
        url: url,
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'openId': window.localStorage.getItem('openId'),
            'time': encrypt.encryptByDES(JSON.stringify(Date.now())),
            'Token': encrypt.encryptByDES(JSON.stringify(qs.parse(data)))
        }
    })
}
